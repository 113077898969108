import React, { Component } from 'react';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            images: [
                'assets/img/banner/exterior.jpg',
                'assets/img/banner/exterior2.jpg',
                'assets/img/banner/interior.jpg'
            ]
        };
    }

    componentDidMount() {
        this.interval = setInterval(this.nextImage, 5000); // Cambia la imagen cada 3 segundos
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    nextImage = () => {
        this.setState(prevState => ({
            currentIndex: (prevState.currentIndex + 1) % prevState.images.length
        }));
    };

    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let { images, currentIndex } = this.state;

        return (
            <div className="banner-area banner-area-1 banner-area-bg" 
                style={{background: `url(${publicUrl}${images[currentIndex]})`}}>
                <div className="container">
                    <div className="banner-area-inner">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="banner-inner text-center">
                                    <p class = "about">Descubre un mundo donde la precisión inmobiliaria y la innovación se unen.</p>
                                    <div className="line" />
                                    <h2 class = "about">Alpine Habitat </h2>
                                    <p class = "about">Redefiniendo el Hogar Perfecto a Través de la Tecnología</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;
