import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return (
            <footer className="footer-area">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <a href="index.html"><img src={publicUrl + "assets/img/logo.png"} alt="Alpine Habitat" width="250" height="100" /></a>
                            </div>
                            <div className="col-md-8 text-md-right mt-3 mt-md-0">
                                {/* Redes sociales de Alpine Habitat */}
                                <ul className="social-area">
                                    <li><a href="https://www.facebook.com/profile.php?id=61557041233808"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                                    <li><a href="https://www.instagram.com/habitat_alpine?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                                    {/* Más iconos de redes sociales si es necesario */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle bg-black">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_about">
                                    <h4 className="widget-title">Alpine Habitat</h4>
                                    <div className="details">
                                        <p>En Alpine Habitat, transformamos el mercado inmobiliario mexicano a través de una gestión innovadora y el uso de tecnología avanzada.</p>
                                        <p><i className="fas fa-map-marker-alt" /> Estado de Hidalgo, México</p>
                                        <p><i className="fas fa-phone-volume" /> +(52) 55 6219 3450</p>
                                        <p><i className="fas fa-envelope" />  habitatalpine@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* Aquí puedes incluir noticias o enlaces relevantes */}
                                <div className="widget widget_newsfeed">
                                    <h4 className="widget-title">Acerca de Nosotros</h4>
                                    <ul>
                                        <li><Link to="/">Inicio</Link></li>
                                        <li><Link to="/about">Acerca de</Link></li>
                                        <li><Link to="/properties">Propiedades</Link></li>
                                        <li><a href="https://alpinehabitat.com.mx/blog/author/admin/">Blog</a></li>
                                        <li><Link to="/contact">Contacto</Link></li>
                                    </ul>
                                </div>
                            </div>
							<div className="col-lg-4 col-md-6">
                                {/* Aquí puedes incluir noticias o enlaces relevantes */}
                                <div className="widget widget_newsfeed">
                                    <h4 className="widget-title">Nuestras Propiedades</h4>
                                    <ul>
                                        <li><Link to="/property-grid">Casas</Link></li>
                                        <li><Link to="/property-lotes">Lotes</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-black">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <p>© 2024 Alpine Habitat - Todos los derechos reservados</p>
                            </div>
                            <div className="col-md-5 text-md-right go-top">
                                <ul>
                                    {/* Enlaces relevantes */}
                                    <li><Link to="/">Inicio</Link></li>
                                    <li><Link to="/about">Acerca de</Link></li>
                                    <li><Link to="/properties">Propiedades</Link></li>
                                    <li><Link to="/contact">Contacto</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer_v1
