import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PropertyGrid extends Component {
    componentDidMount() {
        const $ = window.$;
        $('body').addClass('body-bg');
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div className="blog-page-area pt-5 go-top">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* Lote en Privada Rosal */}
                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/15.png"} alt="Lote Rosal" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Lote en Privada Rosal</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Terrenos desde $524,990</li>
                                        <li class = "about">Tamaño: 105 m² (7m x 15m)</li>
                                    </ul>
                                    <p class = "about">Ubicación privilegiada y servicios de alta calidad.</p>
                                </div>
                            </div>
                        </div>
                        {/* Lote en Privada Tilo */}
                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/16.png"} alt="Lote Tilo" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Lote en Privada Tilo</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Terrenos desde $524,990</li>
                                        <li class = "about">Tamaño: 105 m² (7m x 15m)</li>
                                    </ul>
                                    <p class = "about">Con acceso a amenidades como zonas verdes y juegos infantiles.</p>
                                </div>
                            </div>
                        </div>
                        {/* Lotes Residenciales - Rancho Nuevo Residencial */}
                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/17.png"} alt="Lote Residencial" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Lotes en Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Tamaño: 140 m² (7m x 20m)</li>
                                        <li class = "about">Servicios ocultos y acceso automatizado</li>
                                    </ul>
                                    <p class = "about">Ubicados a solo 5 minutos de Plaza Patio Tulancingo.</p>
                                </div>
                            </div>
                        </div>
                        {/* Otros lotes pueden ser agregados aquí */}
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyGrid;
