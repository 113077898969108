import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Property extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="">
        </div>

        }
}

export default Property
