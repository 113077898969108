import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactForm extends Component {

    componentDidMount() {
        const $ = window.$;
        $('.footer-area.style-two').removeClass('mg-top-100');
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="contact-page-area pd-top-120">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
                        <div className="contact-details-inner mng-box-shadow">
                          <h4 class = "about">Conecta con Alpine Habitat</h4>
                          <p class = "about">Alpine Habitat, un producto de TESEO DATA LAB, ofrece soluciones inmobiliarias avanzadas y personalizadas. Contáctanos para explorar cómo nuestras herramientas y experiencia pueden ayudarte en tu próximo proyecto inmobiliario.</p>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="contact-single-list">
                                <h5 class = "about">Contacto</h5>
                                <ul>
                                  <li class = "about"><img src={publicUrl+"assets/img/icon/location2.png"} alt="Ubicación" />Pachuca de Soto, Hidalgo, Mx.</li>
                                  <li class = "about"><img src={publicUrl+"assets/img/icon/phone.png"} alt="Teléfono" /> +(52) 55 6219 3450</li>
                                  <li class = "about"><img src={publicUrl+"assets/img/icon/mail.png"} alt="" />habitatalpine@gmail.com</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-12 align-self-center text-md-right">
                              <ul className="social-area style-3">
                                <li><a href="https://www.facebook.com/profile.php?id=61557041233808"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                                <li><a href="https://www.instagram.com/habitat_alpine?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                                {/* Más iconos de redes sociales si es necesario */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-5">
                        <form>
                          <div className="row">
                            <div className="col-xl-6 col-md-6">
                              <div className="single-select-inner style-bg-border">
                                <select>
                                  <option value={1}>Información General</option>
                                  <option value={2}>Consultas de Venta</option>
                                  <option value={3}>Soporte Técnico</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label className="single-input-inner style-bg-border">
                                <input type="text" placeholder="Asunto" />
                              </label>
                            </div>
                            <div className="col-xl-12 col-lg-6">
                              <label className="single-input-inner style-bg-border">
                                <input type="text" placeholder="Nombre" />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label className="single-input-inner style-bg-border">
                                <input type="email" placeholder="Email" />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label className="single-input-inner style-bg-border">
                                <input type="text" placeholder="Teléfono" />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="single-input-inner style-bg-border">
                                <textarea placeholder="Mensaje" defaultValue={""} />
                              </label>
                            </div>
                            <div className="col-12 mb-4">
                              <button className="btn btn-base">Enviar Mensaje</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="contact-page-map mg-top-100">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53147.04194036949!2d-98.7834434948125!3d20.101873448741582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a7716f1e038b%3A0x13315c101496b749!2sPachuca%20de%20Soto%2C%20Hgo.!5e0!3m2!1ses!2smx!4v1712367630428!5m2!1ses!2smx" />
                  </div>
                </div>
        }
}

export default ContactForm
