import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyLotes from './section-components/property-lotes';
import Footer from './global-components/footer';

const PropertLotes = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Lotes"  />
        <PropertyLotes />
        <Footer />
    </div>
}

export default PropertLotes