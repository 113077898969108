import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PropertyGrid extends Component {

    componentDidMount() {
        const $ = window.$;
        $('body').addClass('body-bg');
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div className="blog-page-area pt-5 go-top">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* Información de las casas, manteniendo la estructura del código original */}
                        {/* Ejemplo para una casa */}
                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/RanchoNuevo/Arboledas.png"} alt="Casa Arboledas" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/property-details-arboledas">Casa Arboledas en Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p class = "about">Diseño contemporáneo con terraza y habitación en planta baja.</p>
                                    <span className="price about">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span class = "about">Num Hab: <br/> 3</span>
                                    <span className="border-none about">Num baños: <br/> 2</span>
                                    <span class = "about">Tamaño: <br/>114 m²</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/RanchoNuevo/Azucena.png"} alt="Casa Azucena" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/property-details">Casa Azucena en Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about"> 2 plantas, Estacionamiento techado, Zaguan de acero</li>
                                    </ul>
                                    <p class = "about">Dos cómodas plantas que maximiza cada rincón de tu hogar</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 2</span>
                                    <span className="border-none">Num baños: <br/> 1.5</span>
                                    <span>Tamaño: <br/>105 m²</span>
                                </div>
                            </div>
                        </div>
						<div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/RanchoNuevo/Olivo.png"} alt="Casa Olivo" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/property-details-olivo">Casa Olivo en <br/> Rancho Nuevo Residencial</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p class = "about">Con habitación principal amplia, sala y comedor espaciosos.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 4</span>
                                    <span className="border-none">Num baños: <br/> 3.5</span>
                                    <span>Tamaño: <br/>152 m²</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/Alvento/Lia.png"} alt="Casa Lia" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Casa Lía en <br/> Alvento</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p class = "about">Con diseño moderno y lujoso.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 4</span>
                                    <span className="border-none">Num baños: <br/> 3.5</span>
                                    <span>Tamaño: <br/>181 m²</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/MonteOlimpo/Ada.png"} alt="Casa Ada" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Casa Ada en <br/> Monte Olimpo</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">2 plantas, <br/> Estacionamiento para 2 autos</li>
                                    </ul>
                                    <p class = "about">Interiores elegantes y modernos</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 1</span>
                                    <span className="border-none">Num baños: <br/> 2</span>
                                    <span>Tamaño: <br/>128 m²</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/MonteOlimpo/AdaTerraza.png"} alt="Casa Ada Terraza" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Casa Ada con Terraza en <br/> Monte Olimpo</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Con Terraza, Cisterna, <br/> Salón de usos múltiples.</li>
                                    </ul>
                                    <p class = "about">Lujo y comodidad.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 1</span>
                                    <span className="border-none">Num baños: <br/> 1</span>
                                    <span>Tamaño: <br/>217.25 m²</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/MonteOlimpo/Aleen.png"} alt="Casa Aleen" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Casa Aleen en <br/> Monte Olimpo</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Con Terraza, cocina integral, <br/> Vestidor.</li>
                                    </ul>
                                    <p class = "about">Diseño innovador y funcionalidad</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 1</span>
                                    <span className="border-none">Num baños: <br/> 1</span>
                                    <span>Tamaño: <br/>225.27 m²</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-product-wrap style-2">
                                <div className="thumb">
                                    <img src={publicUrl+"assets/img/project/MonteOlimpo/Soul.png"} alt="Casa Ada Terraza" />
                                </div>
                                <div className="product-details-inner">
                                    <h4 class = "about"><Link to="/#">Casa Soul en <br/> Monte Olimpo</Link></h4>
                                    <ul className="meta-inner">
                                        <li class = "about">Con espacio para asador, vestidor.</li>
                                    </ul>
                                    <p class = "about">Espacios amplios.</p>
                                    <span className="price">Consultar precio</span>
                                </div>
                                <div className="product-meta-bottom style-2">
                                    <span>Num Hab: <br/> 3</span>
                                    <span className="border-none">Num baños: <br/> 1</span>
                                    <span>Tamaño: <br/>264 m²</span>
                                </div>
                            </div>
                        </div>
						
						
                        {/* Repetir para cada casa disponible en los PDFs */}
                        {/* ... */}
                    </div>
                </div>
            </div>
        );
    }
}

export default PropertyGrid;
