import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Product extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return <div className="product-area pd-top-118 pd-bottom-90 go-top">
                  <div className="container">
                  <div className="section-title text-center">
                      <h6>Explora Nuestra Selección Exclusiva</h6>
                      <h2>Casas Destacadas con Alpine Habitat</h2>
                    </div>
                    <div className="row justify-content-center">
                      {/* Modelo Azucena */}
                      <div className="col-lg-4 col-md-6">
                        <div className="single-product-wrap">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/product/1.png"} alt="Modelo Azucena" />
                            <Link className="cat" to="/property-details">Modelo Azucena</Link>
                          </div>
                          <div className="product-wrap-details">
                            <h6>Modelo Azucena</h6>
                            <p>2 plantas, 3 habitaciones, 2 baños, estacionamiento para 2 autos, construcción de 114 m2.</p>
                          </div>          
                        </div>
                      </div>
                      {/* Modelo Arboledas */}
                      <div className="col-lg-4 col-md-6">
                        <div className="single-product-wrap">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/product/2.png"} alt="Modelo Arboledas" />
                            <Link className="cat" to="/property-details-arboledas">Modelo Arboledas</Link>
                          </div>
                          <div className="product-wrap-details">
                            <h6>Modelo Arboledas</h6>
                            <p>2 plantas, 4 habitaciones, 3.5 baños, sala comedor amplia, construcción de 152 m2.</p>
                          </div>          
                        </div>
                      </div>
                      {/* Modelo Olivo */}
                      <div className="col-lg-4 col-md-6">
                        <div className="single-product-wrap">
                          <div className="thumb">
                            <img src={publicUrl+"assets/img/product/3.png"} alt="Modelo Olivo" />
                            <Link className="cat" to="/property-details-olivo">Modelo Olivo</Link>
                          </div>
                          <div className="product-wrap-details">
                            <h6>Modelo Olivo</h6>
                            <p>2 plantas, 4 habitaciones, 3.5 baños, habitación principal amplia, construcción de 152 m2.</p>
                          </div>          
                        </div>
                      </div>     
                    </div>
                  </div>
                </div>

        }
}

export default Product
