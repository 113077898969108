import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Product from './section-components/product';
import Service from './section-components/service';
import Property from './section-components/property';
import Testimonial from './section-components/testimonial';
import Client from './section-components/client';
import Footer from './global-components/footer';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Service />
        <Product />
        <Property />
        <Testimonial />
        <Client />
        <Footer />
    </div>
}

export default Home_V1

