import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsArboledas from './section-components/property-arboledas';
import Footer from './global-components/footer';

const PropertyDetails = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Detalles Casa Arboledas"  />
        <PropertyDetailsArboledas />
        <Footer />
    </div>
}

export default PropertyDetails

